import { render, staticRenderFns } from "./Campaign.vue?vue&type=template&id=4278081a&scoped=true"
import script from "./Campaign.vue?vue&type=script&lang=js"
export * from "./Campaign.vue?vue&type=script&lang=js"
import style0 from "./Campaign.vue?vue&type=style&index=0&id=4278081a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4278081a",
  null
  
)

export default component.exports
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QList,QItem,QItemSection,QDialog});
